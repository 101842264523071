import { useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Link,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Facebook, Instagram, Twitter, LinkedIn } from "@mui/icons-material";

import PolicyModal from "src/layouts/main/policymodal/PolicyModal";
import PrivacyModal from "src/layouts/main/privacyModal/PrivacyModal";
import RefundModal from "src/layouts/main/refundModal/RefundModal";

const Footer = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [openPolicyModal, setOpenPolicyModal] = useState(false);

  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);

  const [openRefundPolicyModal, setOpenRefundPolicyModal] = useState(false);

  const [openFaqModal, setOpenFaqModal] = useState(false);

  const handlePolicyModalOpen = () => {
    setOpenPolicyModal(true);
  };

  const handleClose = () => {
    setOpenPolicyModal(false);
  };

  const handlePrivacyPolicyModalOpen = () => {
    setOpenPrivacyPolicyModal(true);
  };

  const handleClosePrivacyModal = () => {
    setOpenPrivacyPolicyModal(false);
  };

  const handleRefundPolicyModalOpen = () => {
    setOpenRefundPolicyModal(true);
  };

  const handleCloseRefundModal = () => {
    setOpenRefundPolicyModal(false);
  };

  const handleFaqModalOpen = () => {
    setOpenFaqModal(true);
  };

  const handleCloseFaqModal = () => {
    setOpenFaqModal(false);
  };

  return (
    <Box
      sx={{
        bgcolor: "#000",
        color: "#fff",
        p: 4,
        px: { xs: 2, md: 12 },
        mt: isSmallScreen ? 10 : 20,
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontSize: { xs: "1.5rem", md: "inherit" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        Don't Miss This Exclusive Opportunity
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography
          variant="body1"
          sx={{
            mb: { xs: 2, md: 0 },
            fontSize: { xs: "0.9rem", md: "inherit" },
          }}
        >
          Enter your email address and start connecting with a better business{" "}
          <br />
          world using our POS products.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            "@media (max-width: 600px)": {
              marginTop: "10px",
            },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Enter your email"
            sx={{
              bgcolor: "#1a1a1a",
              input: { color: "#fff" },
              borderRadius: "30px",
              width: { xs: "100%", md: "auto" },
              "& fieldset": { border: "none" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    sx={{
                      height: "40px",
                      borderRadius: "20px",
                      color: "white",
                    }}
                  >
                    Subscribe
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          mt: 10,
        }}
      >
        <Box sx={{ mb: { xs: 4, md: 0 } }}>
          <img
            src="/assets/footer/flogo.svg"
            alt="POS system"
            onClick={() => navigate("./")}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 3,
            textAlign: "center",
          }}
        >
          <Link
            color="inherit"
            underline="hover"
            onClick={() => navigate("./features")}
          >
            Features
          </Link>
          <Link
            color="inherit"
            underline="hover"
            onClick={handleRefundPolicyModalOpen}
          >
            Refund Policy
          </Link>
          <Link
            href=""
            color="inherit"
            underline="hover"
            onClick={() => navigate("./blog")}
          >
            Blog
          </Link>
          <Link href="#" color="inherit" underline="hover">
            Resource
          </Link>
          <Link
            color="inherit"
            underline="hover"
            onClick={handlePolicyModalOpen}
          >
            Terms & Conditions
          </Link>
          <Link
            color="inherit"
            underline="hover"
            onClick={handlePrivacyPolicyModalOpen}
          >
            Privacy Policy
          </Link>
        </Box>

        <Box></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
          mt: 4,
        }}
      >
        <Box
          sx={{
            bgcolor: "#fff",
            display: "inline-flex",
            px: 1,
            borderRadius: "24px",
          }}
        >
          <IconButton
            href="https://www.facebook.com/profile.php?id=100091501938539"
            sx={{ color: "#F08203" }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/egora_pk/"
            sx={{ color: "#F08203" }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            href="https://twitter.com/egora_global"
            sx={{ color: "#F08203" }}
          >
            <Twitter />
          </IconButton>
          <IconButton
            href="https://www.linkedin.com/company/egora/"
            sx={{ color: "#F08203" }}
          >
            <LinkedIn />
          </IconButton>
        </Box>
      </Box>

      <Typography
        variant="body2"
        sx={{ mt: 4, textAlign: "center", fontSize: "0.8rem" }}
      >
        © 2024 Egora, Inc. All rights reserved.
      </Typography>

      {openPolicyModal && (
        <PolicyModal width="md" open={openPolicyModal} onClose={handleClose} />
      )}

      {openPrivacyPolicyModal && (
        <PrivacyModal
          width="md"
          open={openPrivacyPolicyModal}
          onClose={handleClosePrivacyModal}
        />
      )}

      {openRefundPolicyModal && (
        <RefundModal
          width="md"
          open={openRefundPolicyModal}
          onClose={handleCloseRefundModal}
        />
      )}
    </Box>
  );
};

export default Footer;
