import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Avatar,
  Card,
  CardContent
} from "@mui/material";

const testimonials = [
  {
    name: "Malik Raza",
    role: "Cupbowl",
    avatar: "https://avatars.githubusercontent.com/u/84346215?v=4",
    testimonial:
      "Egon has done a phenomenal job. Our customer experience was fulfilled promptly, and the after-sales services are excellent. It’s great to see such an innovative system in Pakistan.",
    rating: 5
  },
  {
    name: "Basit",
    role: "Oruco Coffee",
    avatar: "https://avatars.githubusercontent.com/u/89645252?v=4",
    testimonial:
      "Egon has done a phenomenal job. Our customer experience was fulfilled promptly, and the after-sales services are excellent. It’s great to see such an innovative system in Pakistan.",
    rating: 5
  },
  {
    name: "Ahsan Bukhari",
    role: "Cafe Delite",
    avatar: "https://avatars.githubusercontent.com/u/515861?v=4",
    testimonial:
      "Egon has done a phenomenal job. Our customer experience was fulfilled promptly, and the after-sales services are excellent. It’s great to see such an innovative system in Pakistan.",
    rating: 5
  }
];

const TestimonialSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Container sx={{ textAlign: "center", py: 8, px: { xs: 4, md: 0, lg: 2 } }} >
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          fontWeight: "bold",
          marginBottom: "130px",
          // color: "primary.main"
        }}
      >
        What our clients say about us
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onMouseEnter={() => handleSlideChange(index)}
              onClick={() => handleSlideChange(index)}
              sx={{
                borderRadius: "16px",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                padding: 4,
                overflow: "visible",
                transform: index === currentSlide ? "scale(1.05)" : "scale(1)",
                transition: "transform 0.3s ease-in-out",
                background:
                  index === currentSlide
                    ? "linear-gradient(360deg, rgb(255 98 14) 0%, rgba(255,140,0,1) 100%)"
                    : "white",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Box
                  sx={{
                    width: 108,
                    height: 108,
                    marginTop: "-83px",
                    padding: "4px",
                    backgroundColor: "#f9931e",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 4px 12px rgba(0,0,0,0.1)"
                  }}
                >
                  <Avatar
                    src={testimonial.avatar}
                    alt={testimonial.name}
                    sx={{
                      width: 100,
                      height: 100,
                      border: "3px solid white"
                    }}
                  />
                </Box>
              </Box>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  {Array.from({ length: testimonial.rating }).map((_, i) => (
                    <Typography
                      key={i}
                      variant="body2"
                      color={index === currentSlide ? "white" : "primary"}
                      sx={{ mx: 0.3, fontSize: "35px" }}
                    >
                      ★
                    </Typography>
                  ))}
                </Box>
                <Typography variant="body2" color="black">
                  <Typography
                    component="span"
                    color={index === currentSlide ? "white" : "primary"}
                    sx={{ fontSize: "35px", verticalAlign: "text-bottom" }}
                  >
                    “
                  </Typography>
                  {testimonial.testimonial}
                  <Typography
                    component="span"
                    color={index === currentSlide ? "white" : "primary"}
                    sx={{ fontSize: "35px", verticalAlign: "text-top" }}
                  >
                    ”
                  </Typography>
                </Typography>
                <Typography
                  variant="h6"
                  color={index === currentSlide ? "white" : "primary"}
                  sx={{ fontWeight: "bold", mt: 2 }}
                >
                  {testimonial.name} , {testimonial.role}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
        {testimonials.map((_, index) => (
          <Box
            key={index}
            onClick={() => handleSlideChange(index)}
            sx={{
              width: index === currentSlide ? "25px" : "10px",
              height: "10px",
              borderRadius: index === currentSlide ? "18%" : "50%",
              bgcolor: index === currentSlide ? "primary.main" : "grey.400",
              mx: 0.5,
              cursor: "pointer"
            }}
          />
        ))}
      </Box>
    </Container>
  );
};

export default TestimonialSection;
