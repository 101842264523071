const colors = {
    colors: {
      primary: "#F08203",
      secondary: "#232124",
      white: "#FFFFFF",
      textPrimary: "#19191C",
      textSecondary: "#F8F9FD",
    },
  };
  
  export default colors;
  