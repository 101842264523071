import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import MainLayout from "../layouts/main";
import SimpleLayout from "../layouts/simple";
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralReportPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: Bar
  BarListPage,
  BarCreatePage,
  BarLevelCreatePage,
  BarEditPage,
  BarAccountPage,
  // Dashboard: Booking
  BookingListPage,
  // Dashboard: Table
  TableAccountPage,
  TableListPage,
  TableCreatePage,
  TableEditPage,
  // Dashboard: Area
  AreaCreatePage,
  AreaEditPage,
  // Dashboard: Desigination
  DesiginationCreatePage,
  DesiginationEditPage,
  // Dashboard: Reservation
  ReservationListPage,
  ReservationCalendarPage,
  ReservationCreatePage,
  ReservationEditPage,
  // Dashboard: Order
  OrderListPage,
  OrderCreatePage,
  OrderEditPage,
  OrderAccountPage,
  OrderDetailsPage,
  // Dashboard: Complaint
  ComplaintListPage,
  ComplaintCreatePage,
  ComplaintEditPage,
  ComplaintAccountPage,
  ComplaintDetailsPage,
  // Dashboard: Notification
  NotificationListPage,
  // NotificationCreatePage,
  // NotificationEditPage,
  // NotificationAccountPage,
  // NotificationDetailsPage,
  // Dashboard: Campaign
  CampaignListPage,
  CampaignCreatePage,
  CampaignEditPage,
  CampaignAccountPage,
  // Dashboard: Settings
  SettingsCreatePage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Menu
  // MenuListPage,
  // MenuEditPage,
  // MenuCardsPage,
  // MenuCreatePage,
  // MenuProfilePage,
  MenuDisplayPage,
  // Dashboard: Category
  CategoryCreatePage,
  CategoryEditPage,
  // Dashboard: Item
  ItemCreatePage,
  ItemEditPage,
  // Dashboard: Choice Group
  ChoiceGroupCreatePage,
  ChoiceGroupEditPage,
  // Dashboard: Food
  FoodCreatePage,
  // Dashboard: Drink
  DrinkCreatePage,
  // Dashboard: Customer
  CustomerListPage,
  CustomerEditPage,
  CustomerCreatePage,
  CustomerAccountPage,
  // Dashboard: POS
  POSPage,
  POSOrderListPage,
  // Dashboard: ORDERS
  OrdersPage,
  // Dashboard: KITCHEN
  KitchenPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  Contact,
  MerchantPage,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,

  // about page
  AboutPage,
  ContactPage,
  BlogPage,
  BlogDetail,
  FeaturesPage,
} from "./elements";

import Pos from "src/sections/features/Pos";
import DigitalOrdering from "src/sections/features/DigitalOrdering";
import TableReservation from "src/sections/features/TableReservation";
import InventoryManagement from "src/sections/features/InventoryManagement";
import KitchenDisplay from "src/sections/features/KitchenDisplay";
import OrderManagement from "src/sections/features/OrderMangement";
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      element: <MainLayout />,

      children: [{ path: "/", element: <MerchantPage />, index: true }],
    },

    //About

    {
      element: <MainLayout />,
      // path: "dashboard",
      children: [{ path: "/about", element: <AboutPage />, index: true }],
    },

    {
      element: <MainLayout />,
      // path: "dashboard",
      children: [{ path: "/contact", element: <ContactPage />, index: true }],
    },

    {
      element: <MainLayout />,
      // path: "dashboard",
      children: [
        { path: "/blog", element: <BlogPage />, index: true },

        // { path: "/blog:id/detail", element: <BlogDetail />, index: true },
        { path: "/blog/:id/detail", element: <BlogDetail />, index: true }, // Updated route
      ],
    },

    {
      element: <MainLayout />,
      // path: "dashboard",
      children: [
        { path: "/features", element: <FeaturesPage />, index: true },

        { path: "/features/pos", element: <Pos />, index: true },
        { path: "/features/digitalOrdering", element: <DigitalOrdering />, index: true },
        { path: "/features/tableReservation", element: <TableReservation />, index: true },
        { path: "/features/inventoryManagement", element: <InventoryManagement />, index: true },
        { path: "/features/kitchenDisplay", element: <KitchenDisplay />, index: true },
        { path: "/features/orderManagement", element: <OrderManagement />, index: true },

        

        
        
        
      ],
    },

    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
