import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import { CheckCircle } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TestimonialSection from "src/pages/Testimonial";
import { useNavigate } from "react-router";

export default function InventoryManagement() {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ px: { xs: 4, md: 0, lg: 2 }, mt: { xs: 2, md: 4 } }}
      >
        <Grid item md={5} xs={12}>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
              sx={{ color: "#F08203" }}
            >
              Take Full Control of Your Inventory with Egora’s Inventory
              Management System
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#4D4E57", textAlign: { xs: "center", md: "left" } }}
            >
              Effortlessly monitor stock levels, minimize waste, and optimize
              costs with Egora’s Inventory Management System. Designed to
              integrate seamlessly with your POS, our solution empowers
              restaurants to maintain accurate inventory records and streamline
              their supply chain operations.
            </Typography>

            <Typography
              variant="h4"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
            >
              Why Choose Egora Inventory Management?
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#4D4E57", textAlign: { xs: "center", md: "left" } }}
            >
              Managing inventory manually can be time-consuming and error-prone.
              Egora’s Inventory Management System automates the process — giving
              you full visibility of stock movements, ingredient usage, and
              reorder requirements — so you can focus on running your restaurant
              more efficiently.
            </Typography>
          </motion.div>

          <Grid item xs={12} mt={2}>
            <motion.div>
              <Button
                variant="contained"
                onClick={() => navigate("/contact")}
                sx={{
                  marginRight: "10px",
                  marginTop: "10px",
                  borderRadius: "20px",
                  color: "white",
                }}
              >
                Book A Demo
              </Button>
            </motion.div>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Box display="flex" justifyContent="center">
              <img
                src="/assets/features/inventory.svg"
                alt="Inventory system"
                width="100%"
              />
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} sx={{ mt: { xs: 2, md: 4 } }}>
          <Typography variant="h3" textAlign="center" gutterBottom>
            Key Features
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ px: { xs: 0, sm: 4, md: 10 }, mt: { xs: 2, md: 4 } }}
          >
            {features.map((feature, index) => (
              <Grid item md={3} xs={12} key={index}>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Card
                    sx={{
                      boxShadow: 3,
                      textAlign: "center",
                      height: "125px",
                      overflow: "hidden",
                      transition: "height 0.3s ease-in-out",
                      "&:hover": {
                        height: "auto",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>

                      <Typography
                        color="textSecondary"
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          transition: "all 0.3s ease-in-out",
                          "&:hover": {
                            WebkitLineClamp: "unset",
                            overflow: "visible",
                          },
                        }}
                      >
                        {feature.description}
                      </Typography>

                      <List dense>
                        {feature.points.map((point, i) => (
                          <ListItem key={i} sx={{ paddingY: "4px" }}>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              <CheckCircle color="primary" fontSize="14px" />
                            </ListItemIcon>

                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontSize: "14px",
                                  color: "rgba(0, 0, 0, 0.8)",
                                },
                              }}
                              primary={point}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "rgba(211, 211, 211, 0.5)",
          py: { xs: 2, md: 4 },
          px: 0,
          textAlign: "center",
          mt: { xs: 6, md: 12 },
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            px: 0,
          }}
        >
          {[
            {
              title: "Seamless Integrations",
              description:
                "Egora POS works in harmony with other Egora modules to create a unified ecosystem:",
              items: [
                "Kitchen Display System (KDS)",
                "Table Reservation System",
                "Inventory Management",
                "Customer Engagement Tools",
                "Delivery Management",
                "Order Management",
              ],
            },
            {
              title: "Benefits of Egora Inventory Management",

              items: [
                "Improved stock accuracy.",
                "Minimized waste and better cost control.",
                "Data-driven decision-making",
              ],
            },
            {
              title: "Built for Every Restaurant Type",
              description:
                "Whether you're running a fine dining establishment or a food truck, our POS system is designed to support your business needs.",
              items: [
                "Fine Dining Restaurants",
                "Fast Food Chains",
                "Cafés & Coffee Shops",
                "Bakeries",
                "Cloud Kitchens",
                "Food Trucks",
              ],
            },
          ].map((section, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              sx={{
                minWidth: 0,
                borderRight: { md: index !== 2 ? "2px dashed white" : "none" },
                pr: { xs: 2, md: 4 },
                pl: { xs: 2, md: 4 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
              >
                {section.title}
              </Typography>
              <Typography variant="body1" sx={{ px: { xs: 2, md: 6 } }}>
                {section.description}
              </Typography>
              <List sx={{ mt: 2, textAlign: "left", pl: { xs: 2, md: 4 } }}>
                {section.items.map((text, i) => (
                  <ListItem
                    key={i}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px", color: "black" }}>
                      <CheckCircle color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2">{text}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ px: { xs: 4, md: 0, lg: 16 }, mt: { xs: 2, md: 4 } }}
      >
        <Grid item xs={12} sx={{ mt: { xs: 4, md: 6 } }}>
          <Typography variant="h3" gutterBottom>
            Get Started with Egora Inventory Management Today
          </Typography>
          <Typography variant="body1" gutterBottom>
            Optimize your inventory processes and reduce operational costs with
            Egora’s smart inventory tracking system.Join the growing community
            of venues simplifying their operations with Egora.
          </Typography>

          <motion.div whileHover={{ scale: 1.05 }}>
            <Button
              variant="contained"
              onClick={() => navigate("/contact")}
              sx={{
                // marginLeft: "20px",
                borderRadius: "20px",
                color: "white",
                marginTop: "10px",
              }}
            >
              Take Control of Your Inventory with Real-Time Tracking | Learn More
            </Button>
          </motion.div>

          <Typography
            variant="body2"
            sx={{ mt: 2, fontStyle: "italic", color: "#4D4E57" }}
          >
            Experience the future of restaurant management with Egora — Where
            Innovation Meets Simplicity.
          </Typography>
        </Grid>
      </Grid>

      <TestimonialSection />
    </>
  );
}

const features = [
  {
    title: "Real-Time Stock Tracking",
    description:
      "Stay on top of your inventory at all times, ensuring seamless stock management and avoiding shortages",
    points: [
      "Automatically syncs with your POS system to update stock levels with every sale.",
      "Track ingredient usage and remaining quantities in real-time.",
      "Set custom low-stock thresholds and receive automatic alerts.",
    ],
  },
  {
    title: "Waste Reduction",
    description:
      "Cut costs by minimizing food wastage, optimizing ingredient usage, and ensuring better inventory control to maximize profitability.",
    points: [
      "Track ingredient consumption patterns.",
      "Identify and reduce excess stock.",
      "Analyze wastage trends to improve efficiency and optimize costs.",
    ],
  },
  {
    title: "Reporting & Forecasting",
    description: "Make smarter inventory decisions with data-driven insights.",
    points: [
      "Generate detailed reports on stock usage and wastage.",
      "Forecast future inventory needs based on historical sales trends.",
      "Optimize ordering schedules to avoid overstocking or stockouts.",
    ],
  },
];
