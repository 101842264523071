import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import { CheckCircle } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TestimonialSection from "src/pages/Testimonial";
import { useNavigate } from "react-router";

export default function TableReservation() {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ px: { xs: 4, md: 0, lg: 2 }, mt: { xs: 2, md: 4 } }}
      >
        <Grid item md={5} xs={12}>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
              sx={{ color: "#F08203" }}
            >
              Keep Your Tables Full and Your Guests Happy
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#4D4E57", textAlign: { xs: "center", md: "left" } }}
            >
              Egora’s Table Reservation System is designed to streamline your
              booking process, optimise table management, and provide a seamless
              dining experience for your guests. Whether accepting bookings
              online or managing walk-ins, Egora empowers your staff to
              efficiently handle reservations and reduce wait times.
            </Typography>

            <Typography
              variant="h4"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
            >
              Why Choose Egora Table Reservation System?
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#4D4E57", textAlign: { xs: "center", md: "left" } }}
            >
              With Egora, you can boost table occupancy, improve customer
              satisfaction, and minimize operational complexities — all from a
              single platform.
            </Typography>
          </motion.div>

          <Grid item xs={12} mt={2}>
            <motion.div>
              <Button
                variant="contained"
                onClick={() => navigate("/contact")}
                sx={{
                  marginRight: "10px",
                  marginTop: "10px",
                  borderRadius: "20px",
                  color: "white",
                }}
              >
                Book A Demo
              </Button>
            </motion.div>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Box display="flex" justifyContent="center">
              <img
                src="/assets/features/reservation.svg"
                alt="POS system"
                width="100%"
              />
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} sx={{ mt: { xs: 2, md: 4 } }}>
          <Typography variant="h3" textAlign="center" gutterBottom>
            Key Features
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ px: { xs: 0, sm: 4, md: 10 }, mt: { xs: 2, md: 4 } }}
          >
            {features.map((feature, index) => (
              <Grid item md={3} xs={12} key={index}>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Card
                    sx={{
                      boxShadow: 3,
                      textAlign: "center",
                      height: "125px",
                      overflow: "hidden",
                      transition: "height 0.3s ease-in-out",
                      "&:hover": {
                        height: "auto",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>
                    

                      <Typography
                        color="textSecondary"
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          transition: "all 0.3s ease-in-out",
                          "&:hover": {
                            WebkitLineClamp: "unset",
                            overflow: "visible",
                          },
                        }}
                      >
                        {feature.description}
                      </Typography>

                      <List dense>
                        {feature.points.map((point, i) => (
                          <ListItem key={i} sx={{ paddingY: "4px" }}>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              <CheckCircle color="primary" fontSize="14px" />
                            </ListItemIcon>

                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontSize: "14px",
                                  color: "rgba(0, 0, 0, 0.8)",
                                },
                              }}
                              primary={point}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "rgba(211, 211, 211, 0.5)",
          py: { xs: 2, md: 4 },
          px: 0,
          textAlign: "center",
          mt: { xs: 6, md: 12 },
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            px: 0,
          }}
        >
          {[
            {
              title: "Insights That Drive Growth",
              description: "Gain valuable insights into your reservations:",
              items: [
                "Daily and weekly reservation reports.",
                "Guest booking history.",
                "Peak hours and table utilization trends.",
              ],
            },
            {
              title: "Seamless Integrations",
              description:
                "Egora Inventory Management works in harmony with other Egora modules to create a unified ecosystem:",
              items: [
                "Point of Sale (POS) System",
                "Kitchen Display System (KDS)",
                "Inventory Management",
                "Payment Gateways",
                "Customer Engagement Tools",
                "Delivery Management",
                "Order Management",
              ],
            },

            {
              title: "Built for Every Restaurant Type",
              description:
                "Whether you're running a fine dining establishment or a food truck, our POS system is designed to support your business needs.",
              items: [
                "Fine Dining Restaurants",
                "Fast Food Chains",
                "Cafés & Coffee Shops",
                "Bakeries",
                "Cloud Kitchens",
                "Food Trucks",
              ],
            },
          ].map((section, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              sx={{
                minWidth: 0,
                borderRight: { md: index !== 2 ? "2px dashed white" : "none" },
                pr: { xs: 2, md: 4 },
                pl: { xs: 2, md: 4 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
              >
                {section.title}
              </Typography>
              <Typography variant="body1" sx={{ px: { xs: 2, md: 6 } }}>
                {section.description}
              </Typography>
              <List sx={{ mt: 2, textAlign: "left", pl: { xs: 2, md: 4 } }}>
                {section.items.map((text, i) => (
                  <ListItem
                    key={i}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px", color: "black" }}>
                      <CheckCircle color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2">{text}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
      </Grid>

         <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
              sx={{ px: { xs: 4, md: 0, lg: 16 }, mt: { xs: 2, md: 4 } }}
            >
              <Grid item xs={12} sx={{ mt: { xs: 4, md: 6 } }}>
                <Typography variant="h3" gutterBottom>
                Get Started with Egora Table Reservation System Today
                </Typography>
                <Typography variant="body1" gutterBottom>
                Boost table occupancy, enhance customer experience, and simplify reservations — all with Egora.
                </Typography>
      
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/contact")}
                    sx={{
                      // marginLeft: "20px",
                      borderRadius: "20px",
                      color: "white",
                      marginTop: "10px",
                    }}
                  >
                    Manage Reservations Effortlessly with Egora | Try Now
                  </Button>
                </motion.div>
      
                <Typography
                  variant="body2"
                  sx={{ mt: 2, fontStyle: "italic", color: "#4D4E57" }}
                >
                  Experience the future of restaurant management with Egora — Where
                  Innovation Meets Simplicity.
                </Typography>
              </Grid>
            </Grid>

      <TestimonialSection />
    </>
  );
}

const features = [
  {
    title: "Online Booking Integration",
    description:
      "Allow your guests to book tables directly from your website, social media, or Egora’s Marketplace App — anytime, anywhere.",
    points: [
      "Real-time availability view.",
      "Customizable booking slots.",
      "Instant booking confirmations.",
    ],
  },
  {
    title: "Waitlist Management",
    description:
      "Manage walk-ins effortlessly with an intelligent waitlist system.",
    points: [
      "Add guests to the waitlist with estimated wait times.",
      "Automatically notify guests when their table is ready via SMS.",
      "Prioritize loyal customers and larger groups.",
    ],
  },
  {
    title: "Automatic Table Assignment",
    description: "Maximize seating efficiency with automatic table allocation.",
    points: [
      "Assign tables based on party size and availability.",
      "Minimize empty tables and waiting times.",
      "Merge or split tables for group bookings.",
    ],
  },
  {
    title: " Reservation Reminders",
    description: "Reduce no-shows with automatic SMS or email reminders.",
    points: ["Customizable reminder templates.", "Set reminder intervals."],
  },
];
