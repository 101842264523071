import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//

import Header from './Header';
import Footer from 'src/sections/footer/Footer';


// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

 

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
         
        }}
      >
        <Outlet />
      </Box>

      <Footer />
    </Box>
  );
}
