import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import { CheckCircle } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TestimonialSection from "src/pages/Testimonial";
import { useNavigate } from "react-router";

export default function DigitalOrdering() {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ px: { xs: 4, md: 0, lg: 2 }, mt: { xs: 2, md: 4 } }}
      >
        <Grid item md={5} xs={12}>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
              sx={{ color: "#F08203" }}
            >
              Elevate Your Restaurant Experience with Egora’s QR Code Digital
              Ordering System
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#4D4E57", textAlign: { xs: "center", md: "left" } }}
            >
              Revolutionize the way your customers place orders with Egora’s QR
              Code Digital Ordering System — a seamless, contactless, and
              efficient solution designed to enhance convenience, speed, and
              customer satisfaction. Whether dining in, ordering from their car,
              or opting for self-service, Egora’s QR ordering system caters to
              every service model with ease.
            </Typography>

            <Typography
              variant="h4"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
            >
              Why Choose Egora QR Code Ordering?
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#4D4E57", textAlign: { xs: "center", md: "left" } }}
            >
              With Egora’s QR Code Ordering, your customers can Scan, Order, and
              Pay directly from their smartphones — all without downloading an
              app. Our flexible ordering system empowers your business to
              deliver faster service, reduce wait times, and increase table
              turnover while enhancing customer convenience.
            </Typography>
          </motion.div>

          <Grid item xs={12} mt={2}>
            <motion.div>
              <Button
                variant="contained"
                onClick={() => navigate("/contact")}
                sx={{
                  marginRight: "10px",
                  marginTop: "10px",
                  borderRadius: "20px",
                  color: "white",
                }}
              >
                Book A Demo
              </Button>
            </motion.div>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Box display="flex" justifyContent="center">
              <img
                src="/assets/features/qrnew.svg"
                alt="POS system"
                width="100%"
              />
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} sx={{ mt: { xs: 2, md: 4 } }}>
          <Typography variant="h3" textAlign="center" gutterBottom>
            QR Code Ordering Types
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ px: { xs: 0, sm: 4, md: 10 }, mt: { xs: 2, md: 4 } }}
          >
            {features.map((feature, index) => (
              <Grid item md={3} xs={12} key={index}>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Card
                    sx={{
                      boxShadow: 3,
                      textAlign: "center",
                      height: "125px",
                      overflow: "hidden",
                      transition: "height 0.3s ease-in-out",
                      "&:hover": {
                        height: "auto",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>

                      <Typography
                        color="textSecondary"
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          transition: "all 0.3s ease-in-out",
                          "&:hover": {
                            WebkitLineClamp: "unset",
                            overflow: "visible",
                          },
                        }}
                      >
                        {feature.description}
                      </Typography>

                      <List dense>
                        {feature.points.map((point, i) => (
                          <ListItem key={i} sx={{ paddingY: "4px" }}>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              <CheckCircle color="primary" fontSize="14px" />
                            </ListItemIcon>

                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontSize: "14px",
                                  color: "rgba(0, 0, 0, 0.8)",
                                },
                              }}
                              primary={point}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "rgba(211, 211, 211, 0.5)",
          py: { xs: 2, md: 4 },
          px: 0,
          textAlign: "center",
          mt: { xs: 6, md: 12 },
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            px: 0,
          }}
        >
          {[
            {
              title: "Seamless Payment Integration",
              description: "Scan, Order, Pay — all in one go.",
              items: [
                "Digital Payments",
                "Payment Gateway Integration",
                "Digital Wallet Transactions",
                "Cash Payments with Automatic Change Calculation",
              ],
            },
            {
              title: "Benefits of QR Code Ordering",

              items: [
                "Faster service with reduced wait times.",
                "Improved customer experience with self-service convenience.",
                "Lower operational costs with fewer service staff required.",
                "Enhanced order accuracy and minimized human errors.",
                "Custom branding and user-friendly interface.",
              ],
            },
            {
              title: "Seamless Integrations",
              description:
                "Egora QR Code Ordering works in harmony with other Egora modules to create a unified ecosystem:",
              items: [
                "Point of Sale (POS) System",
                "Kitchen Display System (KDS)",
                "Inventory Management",
                "Payment Gateways",
                "Customer Engagement Tools",
                "Delivery Management",
                "Order Management",
              ],
            },
            {
              title: "Built for Every Restaurant Type",
              description:
                "Whether you're running a fine dining establishment or a food truck, our POS system is designed to support your business needs.",
              items: [
                "Fine Dining Restaurants",
                "Fast Food Chains",
                "Cafés & Coffee Shops",
                "Bakeries",
                "Cloud Kitchens",
                "Food Trucks",
              ],
            },
          ].map((section, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={3}
              sx={{
                minWidth: 0,
                borderRight: { md: index !== 3 ? "2px dashed white" : "none" },
                pr: { xs: 2, md: 4 },
                pl: { xs: 2, md: 4 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
              >
                {section.title}
              </Typography>
              <Typography variant="body1" sx={{ px: { xs: 2, md: 6 } }}>
                {section.description}
              </Typography>
              <List sx={{ mt: 2, textAlign: "left", pl: { xs: 2, md: 4 } }}>
                {section.items.map((text, i) => (
                  <ListItem
                    key={i}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px", color: "black" }}>
                      <CheckCircle color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2">{text}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ px: { xs: 4, md: 0, lg: 16 }, mt: { xs: 2, md: 4 } }}
      >
        <Grid item xs={12} sx={{ mt: { xs: 4, md: 6 } }}>
          <Typography variant="h3" gutterBottom>
            Get Started with Egora QR Code Digital Ordering Today
          </Typography>
          <Typography variant="body1" gutterBottom>
            Upgrade your restaurant service and offer your customers a fast,
            contactless, and modern ordering experience. Join the growing
            community of venues enhancing their operations with Egora.
          </Typography>

          <motion.div whileHover={{ scale: 1.05 }}>
            <Button
              variant="contained"
              onClick={() => navigate("/contact")}
              sx={{
                borderRadius: "20px",
                color: "white",
                marginTop: "10px",
              }}
            >
              Book a Free Demo
            </Button>
          </motion.div>

          <Typography
            variant="body2"
            sx={{ mt: 2, fontStyle: "italic", color: "#4D4E57" }}
          >
            Experience the future of restaurant management with Egora — Where
            Innovation Meets Simplicity.
          </Typography>
        </Grid>
      </Grid>

      <TestimonialSection />
    </>
  );
}

const features = [
  {
    title: "Dine-In Ordering",
    description:
      "Let customers order from their table without waiting for staff.",
    points: [
      "Assign unique QR codes to each table",
      "Customers scan the QR code to access the menu, place orders, and pay.",
      "Orders are instantly sent to the Kitchen Display System (KDS).",
      "Faster service and reduced staff workload.",
    ],
  },
  {
    title: " Self-Serve Ordering",
    description:
      "Replace traditional kiosks with an affordable, digital self-service ordering system.",
    points: [
      "Customers scan a central QR code at self-service stations.",
      "Ideal for fast food venues, cafés, and takeaway outlets.",
      "Customizable menu interface with easy navigation.",
      "Reduce queues and improve order accuracy.",
    ],
  },
  {
    title: "CarHop Ordering",
    description: "Bring convenience straight to your customer’s car.",
    points: [
      "Customers scan QR codes placed in parking spots.",
      "Place orders and pay directly from their smartphones.",
      "Staff receives the order and delivers food directly to the car.",
      "Perfect for drive-in restaurants or curbside pickups.",
    ],
  },
  {
    title: "Drive-Through Ordering",
    description:
      "Offer a modern alternative to traditional drive-through systems.",
    points: [
      "QR codes displayed at drive-through entry points.",
      "Customers scan, place orders, and pay without leaving their vehicles.",
      "Reduce wait times and increase order efficiency.",
      "Automatically syncs with the Kitchen Display System (KDS).",
    ],
  },
];
