import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen  width="100px" height="100px"  />}>
      <Component {...props} />
    </Suspense>
  );

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const MerchantPage = Loadable(lazy(() => import('../pages/MerchantPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const ContactPage = Loadable(lazy(() => import('../pages/ContactPage')));
export const BlogPage = Loadable(lazy(() => import('../pages/BlogPage')));
export const BlogDetail = Loadable(lazy(() => import('../sections/blogs/BlogDetail')));
export const FeaturesPage = Loadable(lazy(() => import('../pages/FeaturesPage')));







