import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Drawer,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useOffSetTop from "../../hooks/useOffSetTop";
import { bgBlur } from "../../utils/cssStyles";
import { HEADER } from "../../config-global";
import Logo from "../../components/logo";
import colors from "src/color/colors";
import { Link as RouterLink, useLocation } from "react-router-dom";

export default function Header() {
  const theme = useTheme();
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [openFeatures, setOpenFeatures] = useState(false);

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const links = [
    { label: "Home", href: "/" },
    { label: "About Us", href: "/about" },
    { label: "Features", href: "features" },
    { label: "Blogs", href: "/blog" },
    { label: "Contact Us", href: "/contact" },
  ];

  const features = [
    { label: "Point of Sale (POS) System", href: "/features/pos" },
    { label: "QR Code Digital Ordering", href: "/features/digitalOrdering" },
    { label: "Table Reservation System", href: "/features/tableReservation" },
    { label: "Inventory Management", href: "/features/inventoryManagement" },
    { label: "Kitchen Display System (KDS)", href: "/features/kitchenDisplay" },
    { label: "Order Management System", href: "/features/orderManagement" },
  ];

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const handleFeatureClose = () => {
    setHovered(false);
    setAnchorEl(null);
  };

  const isActiveTab = (href) => {
    return (
      activeTab === href ||
      (href === "/blog" && activeTab.startsWith("/blog")) ||
      (href === "features" && activeTab.startsWith("/features")) ||
      (href.startsWith("/features") && activeTab.startsWith("/features"))
    );
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{
        boxShadow: 0,
        paddingX: 6,
        "@media (max-width: 768px)": {
          paddingX: 2,
        },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: colors.colors.textSecondary }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "space-between", md: "center" },
          position: "relative",
        }}
      >
        <Box sx={{ display: { xs: "flex", md: "none" }, position: "relative" }}>
          <IconButton
            onClick={() => setOpenDrawer((prev) => !prev)}
            sx={{ padding: 0 }}
          >
            <MenuIcon sx={{ color: colors.colors.primary }} />
          </IconButton>

          {openDrawer && (
            <Box
              sx={{
                position: "absolute",
                top: "40px",
                left: 0,
                right: 0,
                backgroundColor: colors.colors.white,
                borderRadius: "8px",
                boxShadow: 3,
                zIndex: 1000,
                width: "170px",
              }}
            >
              <List>
                {links.map((link) => (
                  <React.Fragment key={link.href}>
                    <ListItem
                      button
                      onClick={() => {
                        if (link.label !== "Features") {
                          setActiveTab(link.href);
                          setOpenDrawer(false);
                        } else {
                          setHovered((prev) => !prev);
                        }
                      }}
                    >
                      {link.label === "Features" ? (
                        <Box
                          sx={{
                            color: colors.colors.primary,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {link.label}
                          <ExpandMoreIcon
                            sx={{
                              // transform: openFeatures
                              //   ? "rotate(180deg)"
                              //   : "rotate(0deg)",
                              transform: hovered
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "0.3s",
                              fontSize: 20,
                            }}
                          />
                        </Box>
                      ) : (
                        <RouterLink
                          to={link.href}
                          style={{
                            color: colors.colors.primary,
                            textDecoration: "none",
                            width: "100%",
                          }}
                        >
                          {link.label}
                        </RouterLink>
                      )}
                    </ListItem>

                    {link.label === "Features" && hovered && (
                      <Box sx={{ paddingLeft: 1 }}>
                        {features.map((feature) => (
                          <ListItem
                            // button
                            key={feature.href}
                            onClick={() => {
                              setActiveTab(feature.href);
                              setHovered(false);
                              setOpenDrawer(false);
                            }}
                          >
                            <RouterLink
                              to={feature.href}
                              style={{
                                color: colors.colors.primary,
                                textDecoration: "none",
                                width: "100%",
                              }}
                            >
                              {feature.label}
                            </RouterLink>
                          </ListItem>
                        ))}
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            "@media (max-width: 600px)": {
              marginLeft: 4,
            },
          }}
        >
          <Logo />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              background: colors.colors.textSecondary,
              padding: "8px",
              borderRadius: "28px",
            }}
          >
            {links.map((link) =>
              link.label === "Features" ? (
                <Box
                  key={link.href}
                  onMouseEnter={(event) => {
                    setHovered(true);
                    setAnchorEl(event.currentTarget);
                  }}
                  onMouseLeave={handleFeatureClose}
                  sx={{
                    display: "inline-block",
                    position: "relative",
                    padding: "8px 16px",
                    borderRadius: "24px",
                    textAlign: "center",
                    cursor: "pointer",
                    transition: theme.transitions.create(
                      ["background-color", "color"],
                      {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                      }
                    ),
                    backgroundColor:
                      isActiveTab(link.href) || hovered
                        ? colors.colors.textPrimary
                        : "transparent",
                    color:
                      isActiveTab(link.href) || hovered
                        ? colors.colors.white
                        : colors.colors.secondary,
                    "&:hover": {
                      backgroundColor: colors.colors.textPrimary,
                      color: colors.colors.white,
                    },
                  }}
                >
                  {link.label}
                  {isMediumScreen && (
                    <Menu
                      anchorEl={anchorEl}
                      open={hovered}
                      onClose={handleFeatureClose}
                      MenuListProps={{ onMouseLeave: handleFeatureClose }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Box sx={{ width: "600px", padding: 1 }}>
                        <Grid container spacing={1}>
                          {features.map((feature) => (
                            <Grid item xs={6} key={feature.href}>
                              <MenuItem
                                onClick={() => {
                                  setActiveTab(feature.href);
                                  handleFeatureClose();
                                }}
                                component={RouterLink}
                                to={feature.href}
                                sx={{
                                  textAlign: "left",
                                  justifyContent: "center",
                                }}
                              >
                                {feature.label}
                              </MenuItem>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Menu>
                  )}
                </Box>
              ) : (
                <RouterLink
                  key={link.href}
                  to={link.href}
                  onClick={() => setActiveTab(link.href)}
                  style={{
                    padding: "8px 16px",
                    borderRadius: "24px",
                    textAlign: "center",
                    textDecoration: "none",
                    transition: theme.transitions.create(
                      ["background-color", "color"],
                      {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                      }
                    ),
                    backgroundColor:
                      !hovered && isActiveTab(link.href)
                        ? colors.colors.textPrimary
                        : "transparent",
                    color:
                      !hovered && isActiveTab(link.href)
                        ? colors.colors.white
                        : colors.colors.secondary,
                  }}
                >
                  {link.label}
                </RouterLink>
              )
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="outlined"
            target="_blank"
            rel="noopener"
            href="/contact"
            sx={{
              color: colors.colors.primary,
              borderColor: colors.colors.primary,
              borderRadius: "20px",
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              "@media (max-width: 600px)": {
                padding: "6px 12px",
              },
            }}
          >
            Book a Demo
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
