import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import { CheckCircle } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TestimonialSection from "src/pages/Testimonial";
import { useNavigate } from "react-router";

export default function Pos() {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ px: { xs: 4, md: 0, lg: 2 }, mt: { xs: 2, md: 4 } }}
      >
        <Grid item md={5} xs={12}>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
              sx={{ color: "#F08203" }}
            >
              Power Your Restaurant with Egora’s All-in-One POS System
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#4D4E57", textAlign: { xs: "center", md: "left" } }}
            >
              Egora’s Point of Sale (POS) System is the backbone of your
              restaurant operations — designed to simplify day-to-day tasks,
              enhance efficiency, and deliver exceptional customer experiences.
              Whether you run a small café or a bustling restaurant, our
              cloud-based POS empowers your team to take orders, process
              payments, and manage sales effortlessly — all from a single,
              intuitive interface.
            </Typography>

            <Typography
              variant="h4"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
            >
              Why Choose Egora POS?
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#4D4E57", textAlign: { xs: "center", md: "left" } }}
            >
              Our POS System is more than just a cash register — it’s a
              comprehensive solution built to streamline your business and help
              you grow. With a user-friendly design and powerful features, Egora
              POS adapts to your restaurant’s needs, making it the ideal choice
              for dine-in, takeaway, or delivery service models.
            </Typography>
          </motion.div>

          <Grid item xs={12} mt={2}>
            <motion.div>
              <Button
                variant="contained"
                onClick={() => navigate("/contact")}
                sx={{
                  marginRight: "10px",
                  marginTop: "10px",
                  borderRadius: "20px",
                  color: "white",
                }}
              >
                Book A Demo
              </Button>
            </motion.div>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Box display="flex" justifyContent="center">
              <img
                src="/assets/features/pos1.svg"
                alt="POS system"
                width="100%"
              />
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} sx={{ mt: { xs: 2, md: 4 } }}>
          <Typography variant="h3" textAlign="center" gutterBottom>
            Key Features
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ px: { xs: 0, sm: 4, md: 10 }, mt: { xs: 2, md: 4 } }}
          >
            {features.map((feature, index) => (
              <Grid item md={3} xs={12} key={index}>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Card
                    sx={{
                      boxShadow: 3,
                      textAlign: "center",
                      height: "125px",
                      overflow: "hidden",
                      transition: "height 0.3s ease-in-out",
                      "&:hover": {
                        height: "auto",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography color="textSecondary">
                        {feature.description}
                      </Typography>

                      <List dense>
                        {feature.points.map((point, i) => (
                          <ListItem key={i} sx={{ paddingY: "4px" }}>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              <CheckCircle color="primary" fontSize="14px" />
                            </ListItemIcon>

                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontSize: "14px",
                                  color: "rgba(0, 0, 0, 0.8)",
                                },
                              }}
                              primary={point}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "rgba(211, 211, 211, 0.5)",
          py: { xs: 2, md: 4 },
          px: 0,
          textAlign: "center",
          mt: { xs: 6, md: 12 }, // Reduce margin-top on smaller screens
        }}
      >
        <Grid
          container
          spacing={2} // Reduce spacing for small screens
          alignItems="stretch"
          sx={{
            flexWrap: "wrap", // Ensure items wrap on smaller screens
            justifyContent: "center",
            px: 0,
          }}
        >
          {[
            {
              title: "Insights That Drive Growth",
              description:
                "Our POS system doesn’t just process transactions — it gives you the insights you need to grow your business.",
              items: [
                "Daily, weekly, and monthly sales reports.",
                "Best-selling items.",
                "Expense vs. revenue tracking.",
              ],
            },
            {
              title: "Seamless Integrations",
              description:
                "Egora POS works in harmony with other Egora modules to create a unified ecosystem:",
              items: [
                "Kitchen Display System (KDS)",
                "Table Reservation System",
                "Inventory Management",
                "Customer Engagement Tools",
                "Delivery Management",
                "Ledger & Financial Management",
              ],
            },
            {
              title: "Built for Every Restaurant Type",
              description:
                "Whether you're running a fine dining establishment or a food truck, our POS system is designed to support your business needs.",
              items: [
                "Fine Dining Restaurants",
                "Fast Food Chains",
                "Cafés & Coffee Shops",
                "Bakeries",
                "Cloud Kitchens",
                "Food Trucks",
              ],
            },
          ].map((section, index) => (
            <Grid
              item
              key={index}
              xs={12} // Full width on small screens
              sm={6} // Two columns on small screens
              md={4} // Three columns on medium+ screens
              sx={{
                minWidth: 0,
                borderRight: { md: index !== 2 ? "2px dashed white" : "none" }, // Remove border on last item
                pr: { xs: 2, md: 4 },
                pl: { xs: 2, md: 4 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
              >
                {section.title}
              </Typography>
              <Typography variant="body1" sx={{ px: { xs: 2, md: 6 } }}>
                {section.description}
              </Typography>
              <List sx={{ mt: 2, textAlign: "left", pl: { xs: 2, md: 4 } }}>
                {section.items.map((text, i) => (
                  <ListItem
                    key={i}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px", color: "black" }}>
                      <CheckCircle color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2">{text}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
      </Grid>


        <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
              sx={{ px: { xs: 4, md: 0, lg: 16 }, mt: { xs: 2, md: 4 } }}
            >
              <Grid item xs={12} sx={{ mt: { xs: 4, md: 6 } }}>
                <Typography variant="h3" gutterBottom>
                Get Started with Egora POS Today
                </Typography>
                <Typography variant="body1" gutterBottom>
                Transform your restaurant operations with Egora’s POS system. Join the growing community of venues simplifying their business with Egora.
                </Typography>
      
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/contact")}
                    sx={{
                      borderRadius: "20px",
                      color: "white",
                      marginTop: "10px",
                    }}
                  >
                  Get the POS that Grows with Your Restaurant | Book a Free Demo
                  </Button>
                </motion.div>
      
                <Typography
                  variant="body2"
                  sx={{ mt: 2, fontStyle: "italic", color: "#4D4E57" }}
                >
                  Experience the future of restaurant management with Egora — Where
                  Innovation Meets Simplicity.
                </Typography>
              </Grid>
            </Grid>

      <TestimonialSection />
    </>
  );
}

const features = [
  {
    title: "Real-Time Order Management",
    description:
      "Manage dine-in, takeaway, and delivery from one central interface.",
    points: [
      "Create, edit, and manage orders with a few taps.",
      "View order status and progress in real-time.",
      "Assign orders to tables or customers for seamless service.",
      "Automatically sync with Kitchen Display System (KDS) to keep kitchen staff updated.",
    ],
  },
  {
    title: "Payment Flexibility",
    description:
      "Multiple payment options, including QR code and split payments.",
    points: [
      "Static and Dynamic Raast QR code payment.",
      "Payment Terminal integration.",
      "Split payments among multiple methods.",
      "Manage cash payments and calculate change automatically.",
    ],
  },
  {
    title: "Customizable Menu",
    description: "Add unlimited items, variations, and modifiers effortlessly.",
    points: [
      "Add unlimited menu items with detailed descriptions, images, and pricing.",
      "Create item variations like size, flavour, or add-ons.",
      "Add custom modifiers for special requests (e.g., extra cheese, no onions).",
      "Set different menus for different times of the day (Breakfast, Lunch, Dinner).",
    ],
  },
  {
    title: "Table Management",
    description: "Track and optimize seating with real-time table updates.",
    points: [
      "Visual floor plan to assign orders to tables.",
      "Merge, split, or transfer tables with ease.",
      "Track occupied and vacant tables in real-time.",
    ],
  },
];
